class DateFmt {
  /**
   * @param {*} t 需要格式化的时间, 默认当前
   * @param {*} str 格式化输入结果字符
   */
  static format(t = new Date(), str = 'yyyy-MM-dd HH:mm:ss') {
    const paramsTime = t.toString().replace(/-/g, '/')
    const temp = new Date(paramsTime)
    const yyyy = temp.getFullYear()
    const MM = (temp.getMonth() + 1 + '').padStart(2, '0')
    const dd = (temp.getDate() + '').padStart(2, '0')
    const HH = (temp.getHours() + '').padStart(2, '0')
    const mm = (temp.getMinutes() + '').padStart(2, '0')
    const ss = (temp.getSeconds() + '').padStart(2, '0')
    if (str) {
      return str
        .replace('yyyy', yyyy)
        .replace('MM', MM)
        .replace('dd', dd)
        .replace('HH', HH)
        .replace('mm', mm)
        .replace('ss', ss)
    }
    return `${yyyy}-${MM}-${dd} ${HH}:${mm}:${ss}`
  }

  /**
   * @param {*} t 对比时间
   * @param {*} day 相对于对比时间, 多少天, 负值表示过去
   * @param {*} str 格式化样式,支持  yyyy-MM-dd hh:mm:ss
   */
  static getBefore(t = +new Date(), day = 0, str = 'yyyy-MM-dd') {
    if (Object.prototype.toString.call(t) === '[object String]') {
      t = t.replace(/-/g, '/')
    }
    const temp = +new Date(t) + day * 24 * 60 * 60 * 1000
    const date = new Date(temp)
    const yyyy = date.getFullYear()
    const MM = (date.getMonth() + 1 + '').padStart(2, '0')
    const dd = (date.getDate() + '').padStart(2, '0')
    const hh = (date.getHours() + '').padStart(2, '0')
    const mm = (date.getMinutes() + '').padStart(2, '0')
    const ss = (date.getSeconds() + '').padStart(2, '0')
    return str
      .replace('yyyy', yyyy)
      .replace('MM', MM)
      .replace('dd', dd)
      .replace('hh', hh)
      .replace('mm', mm)
      .replace('ss', ss)
  }

  /**
   *
   * @param {*} year 年 Number
   * @param {*} month 月 Number
   * @returns
   */
  static getDays(year, month) {
    if (Object.prototype.toString.call(year) !== '[object Number]') {
      throw new Error('getDays params:year must be Number')
    }
    if (Object.prototype.toString.call(month) !== '[object Number]') {
      throw new Error('getDays params:month must be Number')
    }
    return new Date(year, month + 1, 0).getDate()
  }
}

export default DateFmt
